<template>
  <div class="container-fluid">
    <section class="row justify-content-center row-icons pop">
      <div
        @click="circleBtn('formation-taxi')"
        class="row-icons__group col-6 col-lg-2"
      >
        <div class="allIcons iconBlue">
          <img src="@/assets/icons/formation-taxi.webp" alt="icon-taxi" />
        </div>
        <p>
          <strong
            >Formations<br />
            taxi</strong
          >
        </p>
      </div>
      <div
        @click="circleBtn('formation-vtc')"
        class="row-icons__group col-6 col-lg-2"
      >
        <div class="allIcons iconYellow">
          <img
            src="@/assets/icons/formation-vtc.webp"
            alt="icon-driver-private"
          />
        </div>
        <p>
          <strong
            >Formations<br />
            vtc</strong
          >
        </p>
      </div>
      <div
        @click="circleBtn('formations-taxi-vtc')"
        class="row-icons__group col-10 col-lg-2"
      >
        <div class="allIcons iconCenter">
          <img
            src="@/assets/brand/logo-square-transparent.webp"
            alt="icon-license-driving"
          />
        </div>
        <p>
          <strong
            >Nos<br />
            formations</strong
          >
        </p>
      </div>
      <div
        @click="circleBtn('formation-taxi')"
        class="row-icons__group col-6 col-lg-2"
      >
        <div class="allIcons iconYellow">
          <img
            src="@/assets/icons/formation-taxi-gratuite.webp"
            alt="icon-euro-free-teaching"
          />
        </div>
        <p>
          <strong
            >Financement<br />
            CPF</strong
          >
        </p>
      </div>
      <div
        @click="circleBtn('formations-acceleree-vtc-taxi')"
        class="row-icons__group col-6 col-lg-2"
      >
        <div class="allIcons iconBlue">
          <img
            src="@/assets/icons/formation-rapide.webp"
            alt="icon-chronometer-fast-learning"
          />
        </div>
        <p>
          Formations<br />
          modulables
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "RowIcons",
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".pop");
    });
  },
  methods: {
    circleBtn(itemActif) {
      this.$router.push({
        path: "/formations-taxi-vtc",
        query: { itemActif: itemActif },
      });
    },
    anchorBtn(anchor) {
      if (anchor === "nightLesson") {
        this.$router.push({
          path: "/formations-taxi-vtc#nightLesson",
        });
      }
      if (anchor === "formation-gratuite") {
        this.$router.push({
          path: "/formations-taxi-vtc#formation-gratuite",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row-icons {
  margin-top: 10px;
  padding: 15px 20px;
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: space-around;
  }
  &__group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    & :hover {
      cursor: pointer;
    }
    & img {
      width: 50px;
    }
    & p {
      font-size: 0.8rem;
      text-align: center;
      font-family: "cunia";
      color: $color-text;
      margin-top: 5px;
    }
    & .allIcons {
      width: fit-content;
      padding: 12px 12px;
      margin-bottom: 5px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-content: center;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    & .iconBlue {
      background-color: $color-primary;
      border: 0.2px solid $color-secondary;
    }
    & .iconCenter {
      @include black-gradient;
      border: 0.2px solid $color-secondary;
    }
    & .iconYellow {
      background-color: $color-secondary;
      border: 0.2px solid rgba(0, 0, 0, 0.548);
    }
  }
}
.pop.active {
  @include fade-in(1.5s);
}
</style>
