<template>
  <div class="toggle-section">
    <!------------------------------------------------------>
    <div class="container mt-5">
      <!--Section: Content-->
      <section class="text-center dark-grey-text">
        <!-- Section heading -->
        <h2 class="quantum-title font-weight-bold mb-4 pb-2">
          Nos formations<br /><span class="quantum">taxi & vtc</span>
        </h2>
        <!-- Section description -->
        <p
          id="formations-taxi-vtc"
          class="text-center w-responsive mx-auto mb-5"
        >
          Découvrez nos formations spécialisées en taxi et VTC, conçues pour
          vous préparer au mieux à l'excellence du service de transport
          professionnel.
        </p>

        <!-- Grid row -->
        <div class="row card-group toggle-row">
          <!--Grid column-->
          <div class="col-lg-3 col-6 mb-4">
            <button
              class="toggle-btn"
              data-toggle="formation-taxi"
              @click="toggleItem('formation-taxi')"
            >
              <!--Card-->
              <div class="card testimonial-card">
                <!--Background color-->
                <div class="card-up info-color"></div>
                <!--Avatar-->
                <div class="avatar mx-auto white">
                  <img
                    src="@/assets/icons/formation-taxi.webp"
                    alt="icon-taxi"
                    class="card-img-top"
                    loading="lazy"
                  />
                </div>
                <div class="card-body">
                  <!--Name-->
                  <div class="hr"></div>
                  <!--Quotation-->
                  <h4>Formations<br />taxi</h4>
                </div>
              </div>
              <!--Card-->
            </button>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 col-6 mb-4">
            <!--Card-->
            <button
              class="toggle-btn"
              data-toggle="formation-vtc"
              @click="toggleItem('formation-vtc')"
            >
              <div class="card testimonial-card">
                <!--Background color-->
                <div class="card-up info-color"></div>
                <!--Avatar-->
                <div class="avatar mx-auto white">
                  <img
                    src="@/assets/icons/formation-vtc.webp"
                    alt="icon-driver-private"
                    class="card-img-top"
                    loading="lazy"
                  />
                </div>
                <div class="card-body">
                  <!--Name-->
                  <div class="hr"></div>
                  <!--Quotation-->
                  <h4>Formations<br />vtc</h4>
                </div>
              </div>
            </button>
            <!--Card-->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 col-6 mb-4">
            <!--Card-->
            <button
              class="toggle-btn"
              data-toggle="formations-acceleree-vtc-taxi"
              @click="toggleItem('formations-acceleree-vtc-taxi')"
            >
              <div class="card testimonial-card">
                <!--Background color-->
                <div class="card-up blue-gradient"></div>
                <!--Avatar-->
                <div class="avatar mx-auto white">
                  <img
                    src="@/assets/icons/formation-rapide.webp"
                    alt="icon-license-driving"
                    class="card-img-top"
                    loading="lazy"
                  />
                </div>
                <div class="card-body">
                  <!--Name-->
                  <div class="hr"></div>
                  <!--Quotation-->
                  <h4>Formations<br />modulables</h4>
                </div>
              </div>
            </button>
            <!--Card-->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 col-6 mb-4">
            <!--Card-->
            <button
              class="toggle-btn"
              data-toggle="carte-pro-vtc-taxi"
              @click="toggleItem('carte-pro-vtc-taxi')"
            >
              <div class="card testimonial-card">
                <!--Background color-->
                <div class="card-up indigo"></div>
                <!--Avatar-->
                <div class="avatar mx-auto white">
                  <img
                    src="@/assets/icons/mobilite-taxi.webp"
                    alt="icon-french-map"
                    class="card-img-top"
                    loading="lazy"
                  />
                </div>
                <div class="card-body">
                  <!--Name-->
                  <div class="hr"></div>
                  <!--Quotation-->
                  <h4>Formation<br />Mobilité</h4>
                </div>
              </div>
            </button>
            <!--Card-->
          </div>
          <!--Grid column-->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>
    <!------------------------------------------------------>

    <div class="toggle-content">
      <div class="toggle-item active" id="formation-taxi">
        <ServicesTaxi />
      </div>
      <div class="toggle-item" id="formation-vtc">
        <ServicesVtc />
      </div>
      <div class="toggle-item" id="formations-acceleree-vtc-taxi">
        <ServicesAccelere />
      </div>
      <div class="toggle-item" id="carte-pro-vtc-taxi">
        <ServicesMobilite />
      </div>
    </div>
  </div>
</template>

<script>
import ServicesTaxi from "@/components/services/ServicesTaxi.vue";
import ServicesVtc from "@/components/services/ServicesVtc.vue";
//import ServicesPointPermis from "@/components/services/ServicesPointPermis.vue";
import ServicesAccelere from "@/components/services/ServicesAccelere.vue";
import ServicesMobilite from "@/components/services/ServicesMobilite.vue";
export default {
  components: {
    ServicesTaxi,
    ServicesVtc,
    ServicesAccelere,
    ServicesMobilite,
    //ServicesPointPermis,
  },
  data() {
    return {
      activeItem: "formation-taxi",
    };
  },
  mounted() {
    if (this.$route.query.itemActif) {
      this.activeItem = this.$route.query.itemActif;
      this.toggleItem(this.activeItem);
    } else {
      this.activeItem = "formation-taxi";
    }
  },
  methods: {
    toggleItem(id) {
      this.activeItem = id;

      const toggleBtns = document.querySelectorAll(".toggle-btn");
      toggleBtns.forEach((btn) => {
        if (btn.dataset.toggle === id) {
          btn.classList.add("active");
        } else {
          btn.classList.remove("active");
        }
      });

      const toggleItems = document.querySelectorAll(".toggle-item");
      toggleItems.forEach((item) => {
        if (item.id === id) {
          item.classList.add("active");
        } else {
          item.classList.remove("active");
        }
      });

      const isMobile = /Mobi/.test(navigator.userAgent);

      if (isMobile) {
        const targetElement = document.getElementById("item-1_ou"); // Remplacez "your-element-id" par l'ID de l'élément cible
        if (targetElement) {
          const targetPosition = targetElement.offsetTop;
          window.scrollTo({
            top: targetPosition,
            behavior: "smooth",
          });
        }
      } else {
        // Faire défiler l'écran jusqu'à l'ancre spécifiée sur les ordinateurs de bureau et autres appareils
        const anchorElement = document.querySelector("#formations-taxi-vtc");
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: "smooth" });
        }
      }

      // Annuler le défilement vers le haut sur les appareils mobiles
      /*if (/Mobi/.test(navigator.userAgent)) {
        setTimeout(() => {
          window.scrollBy({
            top: -40,
            behavior: "smooth",
          });
        }, 100);
      }*/
    },
  },
};
</script>

<style lang="scss" scoped>
.quantum-title {
  font-size: 2em;
  font-family: "adam";
  & span {
    font-size: 1.3em;
  }
}
.toggle-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  & .toggle-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 337px) {
      flex-direction: row;
      justify-content: space-around;
    }
    & .avatar {
      margin: 10px 0px -10px 0px;
      & img {
        width: 40px;
        @media screen and (min-width: 992px) {
          width: 50px;
        }
      }
    }
    & .card {
      background-color: $color-primary;
      height: 100%;
      min-width: 140px;
      border: none;
      @media screen and (min-width: 992px) {
        min-width: 160px;
      }
      /*&:hover {
        background-color: $color-secondary;
      }*/
      & .card-body {
        & .hr {
          margin: 2px auto 5px;
          width: 75%;
          height: 1px;
          background-color: $color-secondary;
        }
        & h4 {
          font-family: "cunia";
          color: $color-text-light;
          font-size: 0.9em;
          @media screen and (min-width: 992px) {
            font-size: 1em;
          }
        }
      }
    }
  }
  & .toggle-btn {
    background-color: #002a54;
    border: 0.5px $color-secondary outset;
    border-radius: 10px;
    cursor: pointer;
    transition: scale 0.3s;
  }

  & .toggle-btn:hover {
    color: #002a54;
    border: 0.5px $color-secondary outset;
  }

  & .toggle-btn.active {
    background-color: $color-secondary;
    color: #002a54;
    border: 0.5px $color-secondary inset;
    scale: (94%);
    & .card {
      background-color: $color-secondary;
    }
  }

  & .toggle-content {
    width: 100%;
    margin-top: 20px;
  }

  & .toggle-item {
    display: none;
  }

  & .toggle-item.active {
    display: block;
  }
}
</style>
