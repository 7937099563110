<template>
  <section class="home-card">
    <div class="headerBand">
      <div class="title">
        <p>Découvrez</p>
        <h2>Nos formations taxi et vtc</h2>
      </div>
    </div>
    <div class="container-fluid home-service">
      <div class="home-service__card col-lg-3">
        <div class="thumb card1 reponsive-bg slide-left">
          <div class="mask">
            <h3>Formations taxi</h3>
            <p>
              Envie de <strong>devenir taxi</strong> ? Formez-vous dés
              aujourd'hui et réaliser vos objectifs.
            </p>
            <div @click="circleBtn('formation-taxi')" class="routerLink">
              <router-link to="/">En savoir plus &raquo;</router-link>
            </div>
          </div>
        </div>
        <div class="thumb card2 reponsive-bg slide-left">
          <div class="mask">
            <h3>Modulables</h3>
            <p class="black">
              Optez pour nos
              <strong>formations modulables vtc et taxi</strong> pour plus de
              flexibilité.
            </p>
            <div
              @click="circleBtn('formations-acceleree-vtc-taxi')"
              class="routerLink"
            >
              <router-link to="/">En savoir plus &raquo;</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="home-service__card center col-lg-3">
        <div class="thumb thumb-center card3 reponsive-bg pop">
          <img
            src="@/assets/brand/logo-square-transparent.webp"
            alt="logo-institut-taxi"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="home-service__card col-lg-3">
        <div class="thumb card4 reponsive-bg slide-right">
          <div class="mask">
            <h3>Formations vtc</h3>
            <p>
              Prêt à devenir <strong>vtc</strong> ? Obtenez votre
              <strong>carte VTC</strong> et lancez-vous dans une carrière
              passionnante.
            </p>
            <div @click="circleBtn('formation-vtc')" class="routerLink">
              <router-link to="/">En savoir plus &raquo;</router-link>
            </div>
          </div>
        </div>
        <div class="thumb card5 reponsive-bg slide-right">
          <div class="mask">
            <h3>Formations mobilité</h3>
            <p class="black">
              Diversifiez vos compétences pour les titulaires de la
              <strong>carte taxi</strong> ou <strong>vtc</strong>.
            </p>
            <div @click="circleBtn('carte-pro-vtc-taxi')" class="routerLink">
              <router-link to="/">En savoir plus &raquo;</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeService",
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".slide-right", ".slide-left", "pop");
    });
  },
  methods: {
    circleBtn(itemActif) {
      this.$router.push({
        path: "/formations-taxi-vtc",
        query: { itemActif: itemActif },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-card {
  @include background-cloudy;
  @include background-responsive;
  //background-color: $color-primary;
  padding: 0px 0px 50px 0px;
  & .headerBand {
    padding: 30px 10px;
    background-color: $color-primary;
    @media screen and (min-width: 992px) {
      padding: 30px 0px;
    }
    & p {
      text-align: center;
      line-height: 3px;
      color: $color-secondary;
    }
  }
  & .title {
    text-align: center;
    color: $color-text-light;
  }
  & .home-service {
    //background-image: url("@/assets/pictures/forest.jpg");
    //@include background-responsive;
    display: flex;
    flex-direction: column;
    padding: 50px 15px;
    @media screen and (min-width: 992px) {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 50px 0px;
    }
    &__card {
      & .thumb {
        padding: 20px;
        border-radius: 10px;
        //background-color: $background;
        margin-bottom: 40px;
        color: $color-text-light;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
          rgba(0, 0, 0, 0.23) 0px 6px 6px;
        /*& h3 {
          color: $color-primary;
          font-size: 1.1em;
          margin-bottom: 25px;
        }*/
        & p {
          font-size: 1em;
        }
        & .routerLink {
          text-align: right;
          margin-top: 10px;
          & a {
            background-color: $color-primary;
            color: $color-text-light;
            font-size: 0.7em;
            display: inline-block;
            border: 0.2px outset $color-lighter;
            border-radius: 10px;
            padding: 5px 8px;
            transition: 0.2s;
            &:hover {
              border: 0.1px inset $color-lighter;
              background-color: $background;
              color: $color-primary;
            }
          }
        }
      }
    }
  }
}
/*.reponsive-bg {
  @include background-responsive;
}
.mask {
  background-color: rgba(0, 0, 0, 0.452);
  padding: 10px 15px 20px 15px;
  border-radius: 10px;
}*/
.card1 {
  background-color: $color-primary;
  & h3 {
    color: $color-secondary;
    font-size: 1.1em;
    margin-bottom: 25px;
  }
}
.card2 {
  background-color: $color-secondary;
  & h3 {
    color: $color-primary;
    font-size: 1.1em;
    margin-bottom: 25px;
  }
}
.card3 {
  width: fit-content;
  margin: auto;
}
.card4 {
  background-color: $color-primary;
  & h3 {
    color: $color-secondary;
    font-size: 1.1em;
    margin-bottom: 25px;
  }
}
.card5 {
  background-color: $color-secondary;
  & h3 {
    color: $color-primary;
    font-size: 1.1em;
    margin-bottom: 25px;
  }
}
.black {
  color: black !important;
}
.slide-right.active {
  @include slide-right(1s);
}
.slide-left.active {
  @include slide-left(1s);
}
.pop.active {
  @include fade-in(2s);
}
</style>
